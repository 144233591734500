import styled from "styled-components"
import { motion as m } from "framer-motion"
import { gaps, breaks, modifiers as mods } from "common/styles"
import { pxToRem } from "common/functions"
import { color } from "common/settings"
import { Bullets as B } from "components/Bullets/styles"

const {
  font: {
    sizes: { advert, paragraph, title },
  },
} = mods

interface I_BulletsSliderLane {
  width: number | string
}

export const BulletsSliderLane = styled(m.div)<I_BulletsSliderLane>`
  display: flex;
  flex-wrap: wrap;
  cursor: grab;
  width: 100%;

  & ${B.Block} {
    flex: 0 0 100%;
    padding-right: ${gaps.xxl};
    padding-bottom: ${gaps.xxxl};

    ${breaks.sm.up} {
      flex: 0 0 50%;
    }

    ${breaks.xl.up} {
      flex: 0 0 50%;
    }

    & p {
      ${advert};
      font-weight: 200;
      color: ${color.middle};

      &:nth-of-type(2) {
        ${title};
        margin-top: ${gaps.xl};
        font-weight: 300;
        line-height: 1.32;
        color: ${color.dark};
      }
    }
  }
`
