import React from "react"

import { Image } from "components/Image"
import { Graphic as G } from "./styles"
import { Grid, GridItem } from "components/Grid"
import { Text } from "components/Text"
import { ScrollWrapper } from "components/ScrollWrapper"

interface Props {}

export const GraphicCompany: React.FC<Props> = props => {
  return (
    <Grid modifiers={["maxWidth"]}>
      <GridItem>
        <G.Block>
          <div style={{ margin: "64px auto 32px", maxWidth: 240 }}>
            {ScrollWrapper({
              children: <Image src={"zero-emissions-logo.png"} alt={"logo"} />,
            })}
          </div>
          <div style={{ margin: "24px auto" }}>
            {ScrollWrapper({
              children: Text({
                modifiers: ["subline", "fontAlignCenter"],
                children: <>&darr;</>,
              }),
            })}
          </div>

          {ScrollWrapper({
            children: (
              <G.Tile modifiers={["single"]}>
                {Text({
                  modifiers: ["paragraph", "fontAlignCenter"],
                  children: "Holding Management Services",
                })}
              </G.Tile>
            ),
          })}

          <div style={{ margin: "24px auto" }}>
            {ScrollWrapper({
              children: Text({
                modifiers: ["subline", "fontAlignCenter"],
                children: <>&darr;</>,
              }),
            })}
          </div>
          <div style={{ margin: "64px auto 64px", maxWidth: 220 }}>
            {ScrollWrapper({
              children: <Image src={"get-logo-dark.png"} alt={"logo"} />,
            })}
          </div>
        </G.Block>
      </GridItem>
    </Grid>
  )
}
