import styled from "styled-components"
import { LinkIcon } from "./LinkIcon.styles"

export const Link = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    & ${LinkIcon} {
      transform: translateX(20px);
    }
  }
`
