import styled from "styled-components"

export const HeroInner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
`
