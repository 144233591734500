import { BlockquoteGallery as Block } from "./BlockquoteGallery.styles"
import { BlockquoteGalleryBlockquote as Element } from "./BlockquoteGalleryBlockquote.styles"
import {
  BlockquoteGalleryButton as Button,
  BlockquoteGalleryButtonGroup as ButtonGroup,
} from "./BlockquoteGalleryButton.styles"
import { BlockquoteGalleryImage as Image } from "./BlockquoteGalleryImageInline.styles"

export const BlockquoteGallery = {
  Block,
  Blockquote: {
    Element,
  },
  ButtonGroup,
  Button,
  Image,
}
