import styled, { css } from "styled-components"

import { pxToRem } from "common/functions"
import { breaks, gaps } from "common/styles"

type T_TeaserImage = keyof typeof Modifiers
interface I_TeaserImage {
  modifiers?: T_TeaserImage[]
}

const Modifiers = {
  bullets: () => css`
    ${breaks.md.down} {
      height: auto !important;
    }
  `,
}

export const TeaserImage = styled.div<I_TeaserImage>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${gaps.xxl} ${gaps.xl};

  height: ${pxToRem(560)};
  overflow: hidden;

  ${breaks.lg.up} {
    padding: ${gaps.xxxl} ${gaps.xxl};
    height: ${pxToRem(720)};
  }

  ${breaks.xxl.up} {
    padding: ${gaps.xxxl} ${gaps.xxl};
    height: 80vh;
  }
`
