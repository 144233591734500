import React from "react"

import { I_Bullet, I_Headline, I_Options } from "common/types"
import { Bullets } from "components/Bullets"
import { Grid, GridItem } from "components/Grid"
import { Headline } from "components/Headline"
import { Image, Props as I_Image } from "components/Image"
import { ScrollWrapper } from "components/ScrollWrapper"
import { Text } from "components/Text"

import { TeaserImage as TI } from "./styles"

interface Props {
  options: I_Options
  body: {
    bullets?: I_Bullet[]
    headline: I_Headline
    paragraphI?: string
    caption?: string
    image: I_Image
    logo: I_Image
  }
}

export const TeaserImage: React.FC<Props> = props => {
  const {
    options: { modifiers, headlineSize },
    body: { headline, caption, bullets, image, logo, paragraphI },
  } = props

  const renderBullets = (bullets: I_Bullet[]) => Bullets({ bullets })

  const renderHeadline = (headline: I_Headline, headlineSize: any) => (
    <TI.Header>{Headline({ headline, headlineSize })}</TI.Header>
  )

  const renderImage = (image: I_Image) => (
    <TI.Image>{Image({ ...image })}</TI.Image>
  )

  const renderLogo = (logo: I_Image) => (
    <TI.Image modifiers={["logo"]}>{Image({ ...logo })}</TI.Image>
  )

  const renderCaption = (caption: string) => (
    <Text
      modifiers={["body", "color", "spacingTop"]}
      color={"light"}
      spacingTop={"l"}
    >
      {caption}
    </Text>
  )

  return (
    <>
      <TI.Block modifiers={modifiers}>
        <TI.Content>
          <Grid modifiers={["maxWidth"]}>
            <GridItem>
              {ScrollWrapper({
                children: renderHeadline(headline, headlineSize),
              })}
              {paragraphI &&
                ScrollWrapper({
                  children: Text({
                    modifiers: ["title", "color", "spacingTop", "fontWeight"],
                    weight: 200,
                    spacingTop: "xl",
                    color: "inverted",
                    children: paragraphI,
                  }),
                })}
            </GridItem>
          </Grid>
          {bullets && <TI.Bullets>{renderBullets(bullets)}</TI.Bullets>}
        </TI.Content>
        {logo && renderLogo(logo)}
        {renderImage(image)}
      </TI.Block>
      {caption && renderCaption(caption)}
    </>
  )
}
