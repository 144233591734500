import styled from "styled-components"
import { rgba } from "polished"

import { color } from "common/settings"

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 40;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background-color: ${color.middle};
  background-image: url(/pattern-II.png);

  &:after {
    content: "";
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: ${rgba(color.contrast, 0.48)};
  }

  & .gatsby-image-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
