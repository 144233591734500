import styled from "styled-components"
import { motion as m } from "framer-motion"
import { gaps } from "common/styles"

export const PitchFrame = styled(m.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  padding: ${gaps.xxl} 0;
  width: 100%;
  height: 50vh;
`
