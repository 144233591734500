import React from "react"
import { v4 as uuid } from "uuid"
import { Grid, GridItem } from "components/Grid"
import { Image, Props as I_Image } from "components/Image"
import { Text } from "components/Text"

import { Partners as P } from "./styles"
import { I_Options } from "common/types"

interface I_Logo {
  title: string
  caption?: string
  image: I_Image
}

interface Props {
  options: I_Options
  body: {
    logos: I_Logo[]
  }
}

export const Partners: React.FC<Props> = props => {
  const {
    options: { paddingTop, paddingBottom },
    body: { logos },
  } = props

  const renderLogo = (logo: I_Logo) => {
    const image = logo.image
    return (
      <GridItem key={uuid()}>
        <P.Item>
          <Grid modifiers={["noPadding"]}>
            <GridItem
              modifiers={["flex", "alignCenter"]}
              responsive={{ sm: { span: 6 }, lg: { span: 8 } }}
            >
              <Text modifiers={["title"]}>{logo.title}</Text>
            </GridItem>
            <GridItem
              modifiers={["justifyEnd"]}
              responsive={{ sm: { span: 6 }, lg: { span: 4 } }}
            >
              <P.Image>{Image({ ...image })}</P.Image>
            </GridItem>
          </Grid>
        </P.Item>
        {logo.caption && (
          <Text modifiers={["body", "spacingTop"]} spacingTop={"xl"}>
            {logo.caption}
          </Text>
        )}
      </GridItem>
    )
  }

  return (
    <P.Block>
      <Grid
        modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
        spacingTop={paddingTop}
        spacingBottom={paddingBottom}
      >
        {logos.map(logo => renderLogo(logo))}
      </Grid>
    </P.Block>
  )
}
