import styled from "styled-components"
import { pxToRem } from "common/functions"
import { breaks } from "common/styles"

export const KeyFactsItem = styled.div`
  max-width: ${pxToRem(480)};
  overflow: hidden;

  ${breaks.xxl.up} {
    max-width: ${pxToRem(640)};
  }
`
