import styled, { css } from "styled-components"
import { motion as m } from "framer-motion"
import { applyModifiers, pxToRem } from "common/functions"

export type T_Graphic = keyof typeof Modifiers
export interface I_Graphic {
  modifiers?: T_Graphic[]
}

const Modifiers = {
  minWidth: () => css`
    min-width: ${pxToRem(1000)};
    cursor: move;
  `,
}

export const Graphic = styled(m.div)<I_Graphic>`
  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
