import styled from "styled-components"
import { rgba } from "polished"
import { motion as m } from "framer-motion"
import { color } from "common/settings"

export const CarouselItemImage = styled(m.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120%;
  height: 100%;

  transform: scale(1.2) translate(-50%, -50%);

  &:after {
    content: "";
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${rgba(color.contrast, 0.24)};
  }

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
