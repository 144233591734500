import { Video as Block } from "./Video.styles"
import { VideoFrame as Frame } from "./VideoFrame.styles"
import { VideoImage as Image } from "./VideoImage.styles"
import { VideoPlay as Play } from "./VideoPlay.styles"

export const Video = {
  Block,
  Frame,
  Image,
  Play,
}
