import { breaks } from "common/styles"
import styled from "styled-components"

export const TeaserImagesContent = styled.div`
  overflow: hidden;

  ${breaks.md.up} {
    max-width: 90%;
  }
`
