import styled from "styled-components"
import { pxToRem } from "common/functions"

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 110vh;
  min-height: ${pxToRem(560)};
  overflow: hidden;
  background-image: url(/pattern-II.png);
`
