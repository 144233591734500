import React from "react"
import { v4 as uuidv4 } from "uuid"
import { graphql } from "gatsby"
import { ContentLayout } from "layouts/Content/index.layout"
import { components } from "./components"
import { Seo } from "components/Seo"

interface Props {
  location: Location
  data: any
}

const PageBuilder: React.FC<Props> = props => {
  const {
    data: {
      content: {
        inverted,
        mainHeaderPosAbsolute,
        title,
        description,
        lang,
        content,
      },
    },
  } = props

  const renderComponents = (item: any) => {
    const { type } = item

    if (typeof components[type] !== undefined) {
      const Component = components[type]
      return <Component key={uuidv4()} {...item} />
    }
  }

  return (
    <ContentLayout
      inverted={inverted}
      mainHeaderPosAbsolute={mainHeaderPosAbsolute}
      lang={lang}
      location={props.location}
      title={title}
      description={description}
    >
      <Seo lang={lang} title={title} description={description} />
      {content &&
        content.map((item: any) => renderComponents({ ...item, lang: lang }))}
    </ContentLayout>
  )
}

export default PageBuilder

export const data = graphql`
  query ($slug: String!) {
    content: contentJson(slug: { eq: $slug }) {
      inverted
      mainHeaderPosAbsolute
      title
      type
      lang
      slug
      description
      content {
        type
        options {
          reverse
          headlineSize
          paragraphSize
          modifiers
          paddingTop
          paddingBottom
          inlineLogo
          wide
        }
        body {
          bullets {
            keyStat
            headline
          }
          meta
          source
          accordionItems {
            title
            paragraph
          }
          blockquotes {
            headline {
              I
              II
            }
            paragraphI
            paragraphII
            image {
              src
              alt
            }
          }
          articles {
            headline
            subline
            description
            slug
            image {
              alt
              src
            }
          }
          carouselItems {
            meta
            headline {
              I
              II
            }
            title
            listItems
            imageInline {
              alt
              src
            }
            banner {
              alt
              src
            }
            image {
              src
              alt
            }
            paragraph
          }
          items {
            title
            paragraph
            image {
              alt
              src
            }
          }
          logos {
            title
            caption
            image {
              alt
              src
            }
          }
          rows {
            circles {
              modifiers
              title
              image {
                alt
                src
              }
            }
          }
          image {
            alt
            src
          }
          imageII {
            alt
            src
          }
          headline {
            I
            II
          }
          paragraphI
          paragraphII
        }
      }
    }
  }
`
