import { Variants } from "framer-motion"
import { transition } from "common/settings"

export const AnimationModal: { [key: string]: Variants } = {
  Block: {
    open: {
      scale: 1,
      transition,
    },
    close: {
      scale: 0.94,
      transition,
    },
  },
}
