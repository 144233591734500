import styled from "styled-components"

import { pxToRem } from "common/functions"
import { gaps } from "common/styles"

export const CarouselItemBanner = styled.div`
  position: absolute;
  z-index: 4;
  bottom: ${gaps.xl};
  left: ${gaps.xl};
  width: ${pxToRem(172)};
`
