import styled from "styled-components"
import { motion as m } from "framer-motion"

export const VideoImage = styled(m.div)`
  position: absolute;
  width: 100%;
  height: 100%;

  & .gatsby-image-wrapper-constrained {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`
