import React from "react"
import { RiArrowRightLine } from "react-icons/ri"
import { Text } from "components/Text"
import { T_TextModifiers } from "components/Text/styles/TextModifiers"

import { Link as L } from "./styles"

interface Props {
  title: string
  modifiers?: T_TextModifiers[]
  delegateClick: () => void
}

export const Link: React.FC<Props> = props => {
  const { title, modifiers, delegateClick } = props

  return (
    <L.Block onClick={delegateClick}>
      {Text({
        modifiers: modifiers ? modifiers : ["paragraph"],
        children: (
          <>
            {title} <L.Icon>{RiArrowRightLine({ fontSize: "inherit" })}</L.Icon>
          </>
        ),
      })}
    </L.Block>
  )
}
