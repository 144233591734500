import styled from "styled-components"
import { pxToRem } from "common/functions"
import { color } from "common/settings"

import { Link as L } from "components/Link/styles"
import { breaks, gaps } from "common/styles"

export const TeaserImagesImage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${pxToRem(560)};

  ${breaks.lg.up} {
    min-height: ${pxToRem(640)};
  }

  ${breaks.xxl.up} {
    min-height: ${pxToRem(800)};
  }

  background-color: ${color.dark};
  background-image: url(/pattern-II.png);

  & ${L.Block} {
    position: absolute;
    right: ${gaps.xl};
    bottom: ${gaps.xl};
    color: ${color.inverted};
  }

  & img {
    object-fit: contain;
  }
`
