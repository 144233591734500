import styled from "styled-components"
import { pxToRem } from "common/functions"
import { breaks, gaps } from "common/styles"

export const CarouselItemHeader = styled.div`
  position: absolute;
  z-index: 4;
  padding-top: ${gaps.xxl};
  width: 85%;
  max-width: ${pxToRem(640)};

  ${breaks.xxxl.up} {
    padding-top: ${gaps.xxxxl};
    max-width: ${pxToRem(960)};
  }
`
