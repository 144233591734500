import { Modal as Block } from "./Modal.styles"
import { ModalClose as Close } from "./ModalClose.styles"
import { ModalContainer as Container } from "./ModalContainer.styles"
import { ModalContent as Content } from "./ModalContent.styled"
import { ModalImageInline as ImageInline } from "./ModalImageInline.styles"

export const Modal = {
  Block,
  Close,
  Container,
  Content,
  ImageInline,
}
