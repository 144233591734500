import React from "react"
import { v4 as uuid } from "uuid"

import { I_Headline } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Headline } from "components/Headline"
import { Image, Props as I_Image } from "components/Image"
import { Svg } from "components/Svg"
import { Text } from "components/Text"

import { T_DiagramCircle } from "./styles/DiagramCircle.styles"
import { Diagram as D } from "./styles"

interface I_Circle {
  modifiers?: T_DiagramCircle[]
  title: string
  image: I_Image
}

interface I_Row {
  circles: I_Circle[]
}

interface Props {
  body: {
    headline: I_Headline
    rows: I_Row[]
  }
}

export const Diagram: React.FC<Props> = props => {
  const {
    body: { headline, rows },
  } = props

  const renderHeadline = (headline: I_Headline) => Headline({ headline })

  const renderCircle = (circle: I_Circle) => (
    <D.Circle.Element modifiers={circle.modifiers}>
      <D.Circle.Image>
        {circle.image && (
          <Image title={circle.image.title} src={circle.image.src} />
        )}
        {circle.title && (
          <Text
            modifiers={["body", "fontAlignCenter", "color", "spacingTop"]}
            spacingTop={"m"}
            color={"inverted"}
          >
            {circle.title}
          </Text>
        )}
      </D.Circle.Image>
      {circle.modifiers?.includes("isCenter") && (
        <Svg type={"circlesInverted"} />
      )}
    </D.Circle.Element>
  )

  const renderRows = (row: I_Row) => (
    <D.Row
      key={uuid()}
      modifiers={row.circles.length === 0 ? ["singleItem"] : undefined}
    >
      {row.circles.map(circle => renderCircle(circle))}
    </D.Row>
  )

  return (
    <Grid modifiers={["maxWidth"]}>
      <GridItem>
        <D.Block>
          <Grid>
            <GridItem modifiers={[]}>
              {headline && <div>{renderHeadline(headline)}</div>}
              <D.Figure>{rows.map(row => renderRows(row))}</D.Figure>
            </GridItem>
          </Grid>
        </D.Block>
      </GridItem>
    </Grid>
  )
}
