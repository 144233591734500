import { Variants } from "framer-motion"

export const AnimationBlockqoteGallery: { [key: string]: Variants } = {
  Element: {
    visible: {
      y: 0,
      opacity: 1,
    },
    hidden: {
      y: 40,
      opacity: 0,
    },
  },
}
