import styled from "styled-components"
import { breaks, gaps } from "common/styles"

export const Intro = styled.div`
  margin-top: ${gaps.xxxxl};
  padding: ${gaps.xxl} 0 ${gaps.xxxl};

  ${breaks.lg.up} {
    padding: ${gaps.xxxl} 0 ${gaps.xxxxl};
  }
`
