import React from "react"

import { I_Headline, I_Options } from "common/types"
import { Headline } from "components/Headline"
import { Grid, GridItem } from "components/Grid"
import { Logo } from "components/Logo"
import { Text } from "components/Text"

import { Intro as I } from "./styles"
import { Paragraph as P } from "components/Paragraph/styles"

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
    paragraphI: string
  }
}

export const Intro: React.FC<Props> = props => {
  const {
    options: { headlineSize },
    body: { headline, paragraphI },
  } = props

  const renderHeadline = (headline: I_Headline, headlineSize: string) =>
    Headline({ headline, headlineSize })

  const renderParagraph = (paragraph: string) => (
    <P.Block dangerouslySetInnerHTML={{ __html: paragraph }} />
  )

  return (
    <I.Block>
      <Grid modifiers={["maxWidth"]}>
        <GridItem responsive={{ lg: { span: 10 } }}>
          <I.Column>
            <Text modifiers={["spacingBottom"]} spacingBottom={"s"}>
              {renderHeadline(headline, headlineSize)}
            </Text>
          </I.Column>
        </GridItem>
        {paragraphI && (
          <GridItem responsive={{ lg: { span: 10 } }}>
            {renderParagraph(paragraphI)}
          </GridItem>
        )}
      </Grid>
    </I.Block>
  )
}
