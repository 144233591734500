import { Accordion } from "components/Accordion"
import { Carousel } from "components/Carousel"
import { BulletsSlider } from "components/Bullets/BulletsSlider"
import { BlockquoteGallery } from "components/BlockquoteGallery"
import { HeroHeadline } from "components/Headline/HeroHeadline"
import { Diagram } from "components/Diagram"
import {
  GraphicCircle,
  GraphicScaffold,
  GraphicCompany,
} from "components/Graphics"
import { Hero } from "components/Hero"
import { Intro } from "components/Intro"
import { ImageWrapper } from "components/Image/ImageWrapper"
import { KeyFacts } from "components/KeyFacts"
import { Magazine } from "components/Magazine"
import { Partners } from "components/Partners"
import { Paragraph } from "components/Paragraph"
import { Pitch } from "components/Pitch"
import { Tiles } from "components/Tiles"
import { TeaserImage } from "components/Teasers/TeaserImage"
import { TeaserImages } from "components/Teasers/TeaserImages"
import { TeaserText } from "components/Teasers/TeaserText"
import { TeaserTextImage } from "components/Teasers/TeaserTextImage"
import { TeaserBlockquote } from "components/Teasers/TeaserBlockquote"
import { Video } from "components/Video"
import { World } from "components/World"

export const components: any = {
  accordion: Accordion,
  bulletsSlider: BulletsSlider,
  blockquoteGallery: BlockquoteGallery,
  carousel: Carousel,
  diagram: Diagram,
  graphicCircle: GraphicCircle,
  graphicScaffold: GraphicScaffold,
  graphicCompany: GraphicCompany,
  headline: HeroHeadline,
  hero: Hero,
  intro: Intro,
  image: ImageWrapper,
  keyFacts: KeyFacts,
  magazine: Magazine,
  partners: Partners,
  paragraph: Paragraph,
  pitch: Pitch,
  tiles: Tiles,
  teaserBlockquote: TeaserBlockquote,
  teaserImage: TeaserImage,
  teaserImages: TeaserImages,
  teaserText: TeaserText,
  teaserTextImage: TeaserTextImage,
  video: Video,
  world: World,
}
