import styled from "styled-components"

import { color } from "common/settings"
import { breaks, gaps, modifiers as mods } from "common/styles"
import { Paragraph as P } from "components/Paragraph/styles"

const {
  font: {
    sizes: { subline, tertiary, title, body, small },
  },
} = mods

export const ModalContent = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100vh;
  overflow: scroll;

  & ${P.Block} {
    margin-bottom: ${gaps.xxxl};
    color: ${color.inverted};
    font-weight: 300;

    ${breaks.lg.up} {
      margin-bottom: none;
    }

    ol,
    ul {
      margin: ${gaps.xxl} 0 ${gaps.xxl};
      padding: 0;
      padding-left: ${gaps.xl};

      li {
        ${title}
        font-weight: 200;
        padding-left: ${gaps.l};
      }

      li + li {
        margin-top: ${gaps.xxl};
      }
    }

    & h2 {
      ${tertiary}
      font-weight: 200;
    }

    & h2 + p {
      margin-top: ${gaps.xxl};
    }

    & h3 {
      ${title}
      font-weight: 200;
    }

    & p {
      ${body}
      font-weight: 200;

      &.h3 {
        ${title}
        font-weight: 200;
      }

      &.small {
        ${small}
        opacity: 0.48;
        font-weight: 400;
      }
    }

    ul {
      list-style: none;
    }

    & h3 + p,
    & h3 + h3 {
      padding-top: ${gaps.xxl};
    }
  }
`
