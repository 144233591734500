import styled from "styled-components"
import { motion as m } from "framer-motion"
import { breaks } from "common/styles"

export const WorldImage = styled(m.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 50%;
  width: 75%;
  height: 21%;
  overflow: hidden;
  transform: translateX(-50%);

  ${breaks.md.up} {
    bottom: -10%;
    height: 72%;
  }

  ${breaks.lg.up} {
    bottom: 0%;
    height: 50%;
  }
`
