import React from "react"
import { I_CarouselItem } from "components/Carousel"
import { Grid, GridItem } from "components/Grid"

import { Tile } from "./Tile"
import { I_Options } from "common/types"

interface Props {
  options: I_Options
  body: {
    carouselItems: I_CarouselItem[]
  }
}

export const Tiles: React.FC<Props> = props => {
  const {
    options: { paddingTop, paddingBottom },
    body: { carouselItems },
  } = props

  const renderTile = (item: I_CarouselItem) => Tile(item)

  return (
    <Grid
      modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
      spacingTop={paddingTop}
      spacingBottom={paddingBottom}
    >
      {carouselItems.map((item, index) => (
        <GridItem
          key={index}
          responsive={
            carouselItems.length > 1
              ? { lg: { span: 6 } }
              : { lg: { span: 12 } }
          }
        >
          {renderTile(item)}
        </GridItem>
      ))}
    </Grid>
  )
}
