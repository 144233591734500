import React from "react"

import { I_Options } from "common/types"
import { Image, Props as I_Image } from "components/Image"
import { Grid, GridItem } from "components/Grid"
import { Image as I } from "../styles"

interface Props {
  options: I_Options
  body: {
    image: I_Image
  }
}

export const ImageWrapper: React.FC<Props> = props => {
  const {
    options: { paddingTop, paddingBottom },
    body: { image },
  } = props

  return (
    <Grid
      modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
      spacingTop={paddingTop}
      spacingBottom={paddingBottom}
    >
      <GridItem>
        <I.Block>
          <Image {...image} />
        </I.Block>
      </GridItem>
    </Grid>
  )
}
