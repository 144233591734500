import styled from "styled-components"
import { motion as m } from "framer-motion"
import { pxToRem } from "common/functions"
import { color } from "common/settings"
import { gaps, modifiers as mods } from "common/styles"

const {
  font: {
    sizes: { paragraph },
  },
} = mods

export const BlockquoteGalleryButtonGroup = styled(m.div)`
  position: relative;
  display: flex;
  margin-top: ${gaps.xxxxl};
`

export const BlockquoteGalleryButton = styled.div`
  ${paragraph}

  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};

  border: ${pxToRem(3)} solid ${color.inverted};
  border-radius: 50%;
  cursor: pointer;

  & + & {
    margin-left: ${gaps.l};
  }
`
