import React from "react"
import { Props as I_Article } from "../Article"
import { Image } from "components/Image"
import { ArticleFeature as AF } from "./styles"
import { Text } from "components/Text"
import { Link } from "gatsby"

interface Props extends I_Article {}

export const ArticleFeature: React.FC<Props> = props => {
  const { headline, subline, image, slug } = props

  const renderLogo = () => (
    <div className="logo">
      <Image src={"zero-emissions-logo-inverted.png"} alt={"logo"} />
      {Text({
        modifiers: ["paragraph", "color"],
        color: "inverted",
        children: "Magazine",
      })}
    </div>
  )

  const renderContent = () => (
    <div className="content">
      {Text({
        modifiers: ["subline", "color", "fontWeight", "lineHeight"],
        weight: 400,
        color: "inverted",
        children: <>{headline}</>,
      })}
      {/* {Text({
        modifiers: ["tertiary", "color", "fontWeight", "spacingTop"],
        spacingTop: "l",
        weight: 200,
        color: "inverted",
        children: <>{subline}</>,
      })} */}
    </div>
  )

  return (
    <div style={{ padding: "0 10px" }}>
      <AF.Block>
        <Link to={`/${slug}`}>
          <AF.Inner>
            <AF.Content>
              {renderLogo()}
              {renderContent()}
              <Image src={image.src} alt={image.alt} />
            </AF.Content>
          </AF.Inner>
        </Link>
      </AF.Block>
    </div>
  )
}
