import React from "react"

import { I_Headline, I_Options } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { T_OrderPoints, T_GridPoints } from "components/Grid/styles/GridItem"
import { Headline } from "components/Headline"
import { Image, Props as I_Image } from "components/Image"
import { TeaserImage as TI } from "components/Teasers/TeaserImages/styles"
import { TeaserText as TT } from "components/Teasers/TeaserText/styles"
import { Text } from "components/Text"

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
    paragraphI: any
    caption?: string
    image: I_Image
  }
}

export const TeaserTextImage: React.FC<Props> = props => {
  const {
    options: { reverse, paddingTop, paddingBottom, headlineSize },
    body: { headline, caption, paragraphI, image },
  } = props

  const columns: {
    [key: string]: { responsive: T_GridPoints; order?: T_OrderPoints }
  } = {
    content: {
      responsive: reverse
        ? { md: { span: 6, start: 7 }, xl: { span: 8, start: 6 } }
        : { md: { span: 7 }, xl: { span: 7 } },
      order: { sm: { order: 1 }, md: { order: reverse ? 2 : 1 } },
    },
    image: {
      responsive: { md: { span: 5 }, xl: { span: 5 } },
      order: { sm: { order: 2 }, md: { order: reverse ? 1 : 2 } },
    },
  }

  const renderParagraph = (paragraph: any) => (
    <Text
      modifiers={["spacingTop", "spacingBottom"]}
      spacingTop={"xl"}
      spacingBottom={"xl"}
    >
      <TT.Paragraph dangerouslySetInnerHTML={{ __html: paragraph }} />
    </Text>
  )

  const renderCaption = (caption: string) => (
    <Text
      modifiers={["small", "color", "spacingTop"]}
      color={"light"}
      spacingTop={"l"}
    >
      {caption}
    </Text>
  )

  return (
    <>
      <TI.Block modifiers={["backgroundWhite", "imageContainerLogo"]}>
        <Grid
          modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
          spacingTop={paddingTop}
          spacingBottom={paddingBottom}
        >
          <GridItem
            responsive={columns.content.responsive}
            order={columns.content.order}
          >
            <TI.Content>
              {headline && Headline({ headline, headlineSize })}
              {renderParagraph(paragraphI)}
            </TI.Content>
          </GridItem>
          <GridItem
            responsive={columns.image.responsive}
            order={columns.image.order}
          >
            <TI.Image>
              <Image src={image.src} />
            </TI.Image>
          </GridItem>
          <GridItem order={{ sm: { order: 3 } }}>
            {caption && renderCaption(caption)}
          </GridItem>
        </Grid>
      </TI.Block>
    </>
  )
}
