import styled from "styled-components"
import { rgba } from "polished"

import { pxToRem } from "common/functions"
import { color } from "common/settings"
import { breaks, gaps } from "common/styles"

export const ArticleFeatureContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  height: 90vh;
  min-height: ${pxToRem(560)};

  ${breaks.lg.up} {
    flex-direction: row-reverse;
  }

  & .logo {
    position: absolute;
    z-index: 8;
    top: ${gaps.xxxl};
    left: ${gaps.xxl};
    width: ${pxToRem(140)};
  }

  & .content {
    position: absolute;
    bottom: ${gaps.xxl};
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: ${gaps.xxl} ${gaps.xxl} ${gaps.xxxl};
    width: 100%;
    max-width: ${pxToRem(640)};
    z-index: 4;

    ${breaks.lg.up} {
      padding: ${gaps.xl} ${gaps.xxl};
    }
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${rgba(color.contrast, 0.32)};
  }

  & .gatsby-image-wrapper {
    z-index: 2;
    height: 100%;
  }
`
