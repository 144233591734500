import styled from "styled-components"
import { motion as m } from "framer-motion"
import { pxToRem } from "common/functions"
import { rgba } from "polished"
import { color } from "common/settings"
import { gaps } from "common/styles"

export const ArticleFeature = styled(m.div)`
  margin: 0 auto;
  width: 100%;
  max-width: ${pxToRem(600)};
  height: 100%;
  box-shadow: 0 0 ${pxToRem(16)} ${rgba(color.contrast, 0.24)};

  & + & {
    padding-left: ${gaps.xxl};
  }
`
