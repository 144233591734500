import styled from "styled-components"
import { breaks, gaps, modifiers as mods } from "common/styles"

const {
  font: {
    sizes: { advert, paragraph },
  },
} = mods

interface I_BulletsSlider {
  tileWidth: number | string
}

export const BulletsSlider = styled.div<I_BulletsSlider>`
  padding: ${gaps.xxl} 0 ${gaps.xxxl};
  width: 100%;
  max-width: 100vw;
  overflow-x: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &:-webkit-scrollbar {
    display: none;
  }

  ${breaks.lg.up} {
    padding: ${gaps.xxxl} 0;
  }
`
