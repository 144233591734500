import styled, { css } from "styled-components"
import { gaps } from "common/styles"

import { color } from "common/settings"
import { modifiers as mods } from "common/styles/"
import { applyModifiers } from "common/functions"

type T_TeaserTextParagraph = keyof typeof Modifiers
interface I_TeaserTextParagraph {
  modifiers?: T_TeaserTextParagraph[]
}

const {
  font: {
    sizes: { paragraph, title },
  },
} = mods

const Modifiers = {
  noHeadline: () => css`
    & p {
      ${title}
      font-weight: 300;
      line-height: 1.65;
    }
  `,
}

export const TeaserTextParagraph = styled.div<I_TeaserTextParagraph>`
  & p {
    ${paragraph}
    font-weight: 300;
    line-height: 1.65;
  }

  & li {
    color: ${color.inverted};
  }

  & p + p {
    margin-top: ${gaps.xl};
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
