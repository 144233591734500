import { Graphic as Block } from "./Graphic.styles"
import {
  GraphicLabel as LabelElement,
  GraphicLabelBar as LabelBar,
} from "./GraphicLabel.styles"
import { GraphicLegend as Legend } from "./GraphicLegend.styles"
import { GraphicSvg as SvgElement } from "./GraphicSvg.styles"
import { GraphicCircle as Circle } from "./GraphicCircle.styles"
import { GraphicText as Text } from "./GraphicText.styles"
import { GraphicTile as Tile } from "./GraphicTile.styles"

export const Graphic = {
  Block,
  Label: {
    Element: LabelElement,
    Bar: LabelBar,
  },
  Legend,
  Svg: {
    Element: SvgElement,
    Circle,
  },
  Text,
  Tile,
}
