import React, { useRef } from "react"
import { useScroll, useTransform, motion as m } from "framer-motion"
import { Text } from "components/Text"

import { KeyFacts as K } from "./styles"
import { ScrollWrapper } from "components/ScrollWrapper"

export interface Props {
  keyStat?: string
  headline: string
  description?: string
}

export const KeyFactsItem: React.FC<Props> = props => {
  const { headline, keyStat, description } = props
  const refKeyFactsItem = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: refKeyFactsItem,
    offset: ["end end", "start center"],
  })

  const keyStat_Y = useTransform(scrollYProgress, [0, 1], [30, 0])
  const keyStat_Opacity = useTransform(scrollYProgress, [0, 1], [0, 1])
  const headline_Y = useTransform(scrollYProgress, [0.16, 1], [30, 0])
  const headline_Opacity = useTransform(scrollYProgress, [0.16, 1], [0, 1])

  return (
    <K.Item ref={refKeyFactsItem}>
      <ScrollWrapper>
        {keyStat &&
          Text({
            modifiers: [
              "headline",
              "color",
              "lineHeightText",
              "fontWeight",
              "spacingBottom",
            ],
            spacingBottom: "m",
            color: "middle",
            weight: 300,
            children: <>0{keyStat}.</>,
          })}
      </ScrollWrapper>
      <ScrollWrapper>
        {Text({
          modifiers: ["title", "spacingBottom"],
          spacingBottom: description ? "l" : "xxl",
          children: headline,
        })}
        {description &&
          Text({
            modifiers: ["body", "spacingBottom"],
            spacingBottom: "xxl",
            children: description,
          })}
      </ScrollWrapper>
    </K.Item>
  )
}
