import styled from "styled-components"
import { pxToRem } from "common/functions"
import { color } from "common/settings"
import { Headline as H } from "components/Headline/styles"
import { gaps } from "common/styles"

export const BlockquoteGalleryBlockquote = styled.div`
  display: block;
  max-width: ${pxToRem(880)};
  text-align: center;

  & ${H.Block} h2 span {
    color: ${color.inverted} !important;
  }

  & li,
  & ul {
    list-color: inherit;
  }

  & img {
    width: ${pxToRem(120)};
  }
`
