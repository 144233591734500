import React, { useEffect, useRef, useState } from "react"
import { I_Headline, I_Options } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Text } from "components/Text"
import { Graphic as G } from "./styles"
import { useScroll, useTransform } from "framer-motion"
import { ScrollWrapper } from "components/ScrollWrapper"
import useWindowSize from "hooks/useWindowSize"
import { breakpoints, color } from "common/settings"

interface I_Tile {
  title: string
  paragraph: string
}

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
    items: I_Tile[]
    source: string
  }
}

const colorBar = {
  I: "#53A99C",
  II: "#DA3B32",
  III: "#4C95C7",
}

export const GraphicCircle: React.FC<Props> = props => {
  const {
    options: { paddingTop, paddingBottom },
    body: { headline, items, source },
  } = props

  const { width } = useWindowSize()
  const [circle, setCircle] = useState<any>({ x: 200, y: 200, r: 180 })

  useEffect(() => {
    if (width < breakpoints.md) {
      setCircle({ x: 145, y: 145, r: 136 })
    } else {
      setCircle({ x: 200, y: 200, r: 180 })
    }
  }, [width])

  const refGraphicCircle = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: refGraphicCircle,
    offset: ["start end", "end end"],
  })

  const renderCircle = () => (
    <G.Svg.Element>
      <G.Svg.Circle
        cx={circle.x}
        cy={circle.y}
        r={circle.r}
        pathLength={scrollYProgress}
        className="indicator"
      />
    </G.Svg.Element>
  )

  const renderCircleText = () => (
    <G.Text>
      <>{headline.I}</>
    </G.Text>
  )

  const renderBanner = () => (
    <G.Label.Element>
      <G.Label.Bar color={colorBar.I} />
      <G.Label.Bar color={colorBar.II} />
      <G.Label.Bar color={colorBar.III} />
    </G.Label.Element>
  )

  const renderTile = (item: I_Tile) =>
    ScrollWrapper({
      children: (
        <G.Tile>
          {Text({ modifiers: ["title"], children: item.title })}
          {Text({ modifiers: ["body"], children: item.paragraph })}
        </G.Tile>
      ),
    })

  return (
    <G.Block>
      <Grid
        modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
        spacingTop={paddingTop}
        spacingBottom={paddingBottom}
      >
        <GridItem
          modifiers={["flex", "alignCenter", "justifyCenter"]}
          responsive={{ lg: { span: 6 } }}
        >
          <div>
            <div
              ref={refGraphicCircle}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {renderCircleText()}
              {renderCircle()}
              {renderBanner()}
            </div>
            {ScrollWrapper({
              children: (
                <G.Legend>
                  <div className="row">
                    <>
                      <div
                        style={{ backgroundColor: colorBar.I }}
                        className="index"
                      />
                      {Text({ modifiers: ["body"], children: "Thermal 2%" })}
                    </>
                  </div>

                  <div className="row">
                    <>
                      <div
                        style={{ backgroundColor: colorBar.II }}
                        className="index"
                      />
                      {Text({
                        modifiers: ["body"],
                        children: "Batteries 1%",
                      })}
                    </>
                  </div>

                  <div className="row">
                    <>
                      <div
                        style={{ backgroundColor: colorBar.III }}
                        className="index"
                      />
                      {Text({
                        modifiers: ["body"],
                        children: "Mechanical 1%",
                      })}
                    </>
                  </div>

                  <div className="row">
                    <>
                      <div
                        style={{ backgroundColor: color.highlight }}
                        className="index"
                      />
                      {Text({
                        modifiers: ["body"],
                        children: "Pumped Hydro 96%",
                      })}
                    </>
                  </div>
                </G.Legend>
              ),
            })}
          </div>
        </GridItem>

        <GridItem
          modifiers={["flex", "alignCenter", "justifyCenter"]}
          responsive={{ lg: { span: 6 } }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {items.map(item => renderTile(item))}
          </div>
        </GridItem>
        <GridItem>
          {ScrollWrapper({ children: Text({ children: source }) })}
        </GridItem>
      </Grid>
    </G.Block>
  )
}
