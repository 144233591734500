import React, { useRef } from "react"
import { MotionValue, useScroll, useTransform } from "framer-motion"
import { I_Headline, I_Options } from "common/types"

import { Headline } from "components/Headline"
import { Grid, GridItem } from "components/Grid"
import { Text } from "components/Text"

import { T_PitchText } from "./styles/PitchText.styles"
import { Pitch as P } from "./styles"

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
    paragraphI: string
    paragraphII: string
  }
}

export const Pitch: React.FC<Props> = props => {
  const {
    options: { paragraphSize },
    body: { headline, paragraphI, paragraphII },
  } = props

  const refPitch = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: refPitch,
    offset: ["start end", "center start"],
  })

  const textI_Opa = useTransform(scrollYProgress, [0.4, 0.48], [0, 1])
  const textII_Opa = useTransform(
    scrollYProgress,
    [0, 0.56, 0.64, 0.8],
    [0, 0, 1, 0]
  )
  const textII_Y = useTransform(
    scrollYProgress,
    [0, 0.56, 0.64, 0.8],
    [50, 50, 0, -50]
  )

  const textIII_Opa = useTransform(scrollYProgress, [0, 0.8, 0.9], [0, 0, 1])
  const textIII_Y = useTransform(
    scrollYProgress,
    [0, 0.8, 0.9, 1],
    [50, 50, 0, -25]
  )

  console.log(paragraphSize)

  const renderTextContainer = (
    content: string,
    opacity: MotionValue<number>,
    y: MotionValue<number>,
    modifiers?: T_PitchText[]
  ) => (
    <P.Text style={{ opacity, y }} modifiers={modifiers}>
      <Text
        type="h3"
        modifiers={
          paragraphSize
            ? [paragraphSize, "fontWeight", "color"]
            : ["subline", "fontWeight", "color"]
        }
        color={"light"}
        weight={300}
      >
        {content}
      </Text>
    </P.Text>
  )

  const renderHeadlineContainer = (
    headline: I_Headline,
    opacity: MotionValue<number>,
    modifiers?: T_PitchText[]
  ) => (
    <P.Text style={{ opacity }} modifiers={modifiers}>
      {Headline({ headline, headlineSize: "subline" })}
    </P.Text>
  )

  return (
    <P.Block ref={refPitch}>
      <P.Frame>
        <Grid modifiers={["maxWidth"]}>
          <GridItem responsive={{ md: { span: 6 } }}>
            <div style={{ position: "relative", marginTop: 96 }}>
              {renderHeadlineContainer(headline, textI_Opa)}
            </div>
          </GridItem>
          <GridItem
            modifiers={["relativeFrontAbsoluteFull"]}
            responsive={{ md: { span: 6 } }}
          >
            {renderTextContainer(paragraphI, textII_Opa, textII_Y)}
            {renderTextContainer(paragraphII, textIII_Opa, textIII_Y)}
          </GridItem>
        </Grid>
      </P.Frame>
    </P.Block>
  )
}
