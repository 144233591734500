import styled, { css } from "styled-components"
import { rgba } from "polished"
import { applyModifiers, pxToRem } from "common/functions"
import { color } from "common/settings"
import { breaks } from "common/styles"

type T_TeaserImageImage = keyof typeof Modifiers
interface I_TeaserImageImage {
  modifiers?: T_TeaserImageImage[]
}

const Modifiers = {
  logo: () => css`
    display: flex;
    align-items: flex-end;
    position: absolute;
    z-index: 10;
    bottom: 0;
    width: ${pxToRem(132)};
    height: auto;

    ${breaks.md.up} {
      width: ${pxToRem(188)};
    }

    ${breaks.lg.up} {
      width: ${pxToRem(232)};
    }

    & .gatsby-image-wrapper {
      width: 100%;
      height: auto;
    }

    & img {
      object-fit: contain !important;
    }

    &:after {
      display: none;
    }
  `,
}

export const TeaserImageImage = styled.div<I_TeaserImageImage>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      ${rgba(color.contrast, 0)},
      ${rgba(color.contrast, 0.32)}
    );
  }

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  & img {
    object-fit: cover;
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)};
`
