import styled from "styled-components"

export const BlockquoteGalleryImage = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 3;
  width: 70%;
  transform: translate(-50%, -50%);
  opacity: 0.24;
`
