import styled, { css } from "styled-components"
import { applyModifiers, pxToRem } from "common/functions"
import { modifiers as mods, gaps } from "common/styles"

const {
  font: {
    sizes: { title },
  },
} = mods

export type T_AccordionContent = keyof typeof Modifiers

export interface I_AccordionContent {
  modifiers?: T_AccordionContent[]
}

const Modifiers = {
  open: () => css`
    max-height: ${pxToRem(5000)};
  `,
}

export const AccordionContent = styled.div<I_AccordionContent>`
  margin-top: ${gaps.xxl};
  max-height: ${pxToRem(0)};
  overflow: hidden;

  transition: max-height 0.75s ease-in-out;

  & p {
    ${title}

    &:last-of-type {
      padding-bottom: ${gaps.xl};
    }
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)};
`
