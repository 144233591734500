import styled, { css } from "styled-components"
import { breaks, gaps } from "common/styles"
import { applyModifiers, pxToRem } from "common/functions"
import { color } from "common/settings"

export type T_GraphicTile = keyof typeof Modifiers
export interface I_GraphicTile {
  modifiers?: T_GraphicTile[]
}

const Modifiers = {
  single: () => css`
    & div:first-of-type {
      flex: 1;

      ${breaks.sm.up} {
        flex: 1;
      }

      ${breaks.sm.up} {
        flex: 1;
      }

      ${breaks.lg.up} {
        flex: 1;
      }
    }
  `,
  squared: () => css`
    position: relative;
    width: ${pxToRem(160)};
    height: ${pxToRem(160)};

    & + & {
      margin-left: ${gaps.xl};
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: ${pxToRem(80)};
      width: ${pxToRem(1)};
      background: ${color.dark};
      transform: translate(-50%, 100%);
    }
  `,
  emphasized: () => css`
    margin-top: ${gaps.xxxl};
    border: 0;
    border-top: ${pxToRem(3)} solid ${color.dark};
  `,
}

export const GraphicTile = styled.div<I_GraphicTile>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${gaps.xl};
  margin-bottom: ${gaps.l};
  border: ${pxToRem(1)} solid ${color.highlightLight};

  ${breaks.sm.up} {
    flex-direction: row;
    margin-bottom: ${gaps.xl};
  }

  & div {
    flex: 1;
  }

  & div:first-of-type {
    flex: 1;

    ${breaks.sm.up} {
      flex: 0 0 ${pxToRem(160)};
    }

    ${breaks.sm.up} {
      flex: 0 0 ${pxToRem(180)};
    }

    ${breaks.lg.up} {
      flex: 0 0 ${pxToRem(220)};
    }
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
