import styled from "styled-components"
import { gaps } from "common/styles"
import { Headline as H } from "components/Headline/styles"
import { color } from "common/settings"

export const HeroContent = styled.div`
  position: absolute;
  bottom: ${gaps.xxl};
  z-index: 3;
  width: 100%;

  & ${H.Block} {
    & h2 span {
      font-weight: 200 !important;
      color: ${color.inverted} !important;
    }
  }
`
