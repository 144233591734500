import styled, { css } from "styled-components"
import { breaks, gaps } from "common/styles"
import { applyModifiers } from "common/functions"
import { color } from "common/settings"

export type T_Headline = keyof typeof Modifiers

interface I_Headline {
  modifiers?: T_Headline[]
}

const Modifiers = {
  inverted: () => css`
    padding: ${gaps.xxl} ${gaps.xl};
    background-color: ${color.dark};
    background-image: url(/pattern-II.png);

    ${breaks.lg.up} {
      padding: ${gaps.xxxl} ${gaps.xxl};
    }

    & h2 > span {
      color: ${color.inverted} !important;
    }
  `,
  isTop: () => css`
    margin-top: 0;

    ${breaks.md.up} {
      margin-top: ${gaps.xl};
    }

    ${breaks.lg.up} {
      margin-top: ${gaps.xxl};
    }
  `,
}

export const Headline = styled.div<I_Headline>`
  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
