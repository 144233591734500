import { Hero as Block } from "./Hero.styles"
import { HeroContent as Content } from "./HeroContent.styles"
import { HeroHeader as Header } from "./HeroHeader.styles"
import { HeroHeadline as Headline } from "./HeroHeadline.styles"
import { HeroImage as Image } from "./HeroImage.styles"
import { HeroInner as Inner } from "./HeroInner.styles"

export const Hero = {
  Block,
  Content,
  Header,
  Headline,
  Image,
  Inner,
}
