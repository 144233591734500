import React, { useState } from "react"

import { I_Headline, I_Options } from "common/types"
import { Text } from "components/Text"

import { Paragraph as P } from "components/Paragraph/styles"
import { Grid, GridItem } from "components/Grid"
import { ScrollWrapper } from "components/ScrollWrapper"
import { AccordionItem } from "./AccordionItem"
import { Headline } from "components/Headline"

export interface I_AccordionItem {
  title: string
  paragraph: string
}

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
    accordionItems: I_AccordionItem[]
  }
}

export const Accordion: React.FC<Props> = props => {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const {
    options: { paddingTop, paddingBottom },
    body: { headline, accordionItems },
  } = props

  const renderAccordionItem = (item: I_AccordionItem, index: number) => (
    <AccordionItem
      index={index}
      activeIndex={activeIndex}
      {...item}
      delegateClick={newIndex => setActiveIndex(newIndex)}
    />
  )

  return (
    <Grid
      modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
      spacingTop={paddingTop}
      spacingBottom={paddingBottom}
    >
      {headline && (
        <GridItem>
          {ScrollWrapper({
            children: Headline({ headline, headlineSize: "headline" }),
          })}
        </GridItem>
      )}
      <GridItem>
        {accordionItems.map((item, index) => renderAccordionItem(item, index))}
      </GridItem>
    </Grid>
  )
}
