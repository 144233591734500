import React, { Fragment, useRef, useState } from "react"
import { motion as m } from "framer-motion"
import { I_Headline } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Props as I_Image } from "components/Image"

import { Article, Props as I_Article } from "./Article"
import { ArticleFeature } from "./ArticleFeature"
import { Hero } from "./Hero"
import { gaps } from "common/styles"
import { ArticleFeature as AF } from "./ArticleFeature/animation"

interface Props {
  body: {
    headline: I_Headline
    paragraphI: string
    image: I_Image
    articles: I_Article[]
  }
}

export const Magazine: React.FC<Props> = props => {
  const {
    body: { headline, paragraphI, articles, image },
  } = props

  const [activeState, setActiveState] = useState<number>(0)

  const refDragConstraints = useRef<HTMLDivElement>(null)

  return (
    <>
      {/* {Grid({
        modifiers: ["noPadding"],
        children: GridItem({
          children: Hero({ headline, paragraph: paragraphI, image }),
        }),
      })} */}
      <div
        ref={refDragConstraints}
        style={{
          width: "100vw",
          overflow: "hidden",
          paddingBottom: `${gaps.xl}`,
        }}
      >
        <div style={{ position: "relative" }}>
          {articles.map((article, index) => (
            <div style={{ position: "relative", marginBottom: "30px" }}>
              {ArticleFeature({ ...article })}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
