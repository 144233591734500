import styled from "styled-components"
import { pxToRem } from "common/functions"
import { breaks } from "common/styles"

export const GraphicSvg = styled.svg`
  width: ${pxToRem(300)};
  height: ${pxToRem(300)};
  transform: rotate(-90deg);

  ${breaks.md.up} {
    width: ${pxToRem(400)};
    height: ${pxToRem(400)};
  }
`
