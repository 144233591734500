import styled from "styled-components"
import { pxToRem } from "common/functions"
import { color } from "common/settings"
import { gaps } from "common/styles"

export const ModalClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  z-index: 10;

  top: ${gaps.xl};
  left: ${gaps.xl};
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};

  border-radius: 50%;
  border: ${pxToRem(2)} solid ${color.inverted};
  cursor: pointer;
`
