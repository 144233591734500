import React, { useState } from "react"
import { motion as m, Variants } from "framer-motion"

import { I_Headline } from "common/types"
import { Image, Props as I_Image } from "components/Image"
import { Link } from "components/Link"
import { Modal } from "components/Modal"
import { Text } from "components/Text"
import { ScrollWrapper } from "components/ScrollWrapper"

import { Carousel as C } from "./styles"
import { AnimationCarousel as AC } from "./animation"
import { Grid, GridItem } from "components/Grid"

interface Props {
  itemLength: number
  activeIndex: number
  index: number
  meta?: string
  title?: string
  headline: I_Headline
  listItems?: string[]
  paragraph?: string
  image: I_Image
  imageInline?: I_Image
  banner?: I_Image
  lang: string
  delegateClick: (newActiveIndex: number) => void
}

export const CarouselItem: React.FC<Props> = props => {
  const {
    itemLength,
    activeIndex,
    banner,
    index,
    meta,
    listItems,
    headline,
    title,
    paragraph,
    image,
    imageInline,
    lang,
    delegateClick,
  } = props
  const [open, setOpen] = useState<boolean>(false)
  const [indexState] = useState<number>(index)

  const renderTextWrapper = (
    el: JSX.Element,
    variants: Variants,
    index: number
  ) => {
    return (
      <m.div
        style={{ display: "inline" }}
        animate={activeIndex === index ? "active" : "inActive"}
        variants={variants}
      >
        {el}
      </m.div>
    )
  }

  const renderSingleHeadline = (headline: string | JSX.Element) =>
    Text({
      type: "span",
      modifiers: ["subline", "color", "fontWeight"],
      weight: 200,
      color: "inverted",
      children: headline,
    })

  const renderChildItemWrapper = (children: JSX.Element) => (
    <C.Item.Element
      custom={{ itemLength }}
      onClick={() => delegateClick(index)}
      key={index}
      initial={false}
      animate={activeIndex === index ? "active" : "inActive"}
      variants={AC.Item}
    >
      <C.Item.Inner active={activeIndex === index}>
        <Grid modifiers={["maxWidth"]}>
          <GridItem>
            <C.Item.Header>{children}</C.Item.Header>
          </GridItem>
        </Grid>
        {banner && (
          <C.Item.Banner>
            <Image {...banner} />
          </C.Item.Banner>
        )}
        <C.Item.Image
          initial={false}
          animate={activeIndex === index ? "active" : "inActive"}
          variants={AC.Image}
        >
          <Image {...image} />
        </C.Item.Image>
      </C.Item.Inner>
    </C.Item.Element>
  )

  const renderListItem = (item: string, index: number) =>
    ScrollWrapper({
      children: Text({ modifiers: ["paragraph"], children: item }),
    })

  const renderModal = () => (
    <Modal
      delegateOpen={updateOpen => setOpen(updateOpen)}
      open={open}
      title={title}
      paragraph={paragraph}
      image={image}
      imageInline={imageInline}
    />
  )

  return (
    <>
      {renderChildItemWrapper(
        <>
          {renderTextWrapper(
            <>
              {renderSingleHeadline(
                <>
                  {meta &&
                    ScrollWrapper({
                      children: Text({
                        modifiers: ["title", "spacingBottom"],
                        spacingBottom: "l",
                        children: meta,
                      }),
                    })}
                  {ScrollWrapper({
                    children: (
                      <>
                        {headline.I}
                        {headline.II && ` ${headline.II}`}
                      </>
                    ),
                  })}
                </>
              )}
            </>,
            AC.ItemHeadlineI,
            indexState
          )}
          <br />
          {listItems &&
            listItems.map((item, index) => renderListItem(item, index))}
          {paragraph &&
            ScrollWrapper({
              children: renderTextWrapper(
                <Link
                  modifiers={["title"]}
                  title={lang === "en" ? "Learn More" : "Mehr erfahren"}
                  delegateClick={() => setOpen(!open)}
                />,
                AC.ItemHeadlineI,
                indexState
              ),
            })}
        </>
      )}
      {renderModal()}
    </>
  )
}
