import styled, { css } from "styled-components"
import { rgba } from "polished"
import { breaks, gaps } from "common/styles"
import { Link as L } from "components/Link/styles"
import { color } from "common/settings"

interface I_CarouselItemInner {
  active: boolean
}

export const CarouseItemInner = styled.div<I_CarouselItemInner>`
  position: absolute;
  z-index: 4;
  padding: ${gaps.xxl} 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;

  ${({ active }) =>
    !active &&
    css`
      cursor: initial;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${rgba(color.contrast, 0.56)};
        transition: background-color 0.3s ease-in-out;
      }

      &:hover:after {
        background-color: ${rgba(color.contrast, 0.32)};
      }
    `}

  ${breaks.lg.up} {
    padding: ${gaps.xxxl} ${gaps.xxl};
  }

  ${L.Block} {
    padding-top: ${gaps.xxl};
    color: ${color.inverted};
  }
`
