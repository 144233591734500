import { Variants } from "framer-motion"
import { transition } from "common/settings"

const localTransitionImage = { ...transition, duration: 2.5, delay: 0.5 }
const localTransitionItem = { ...transition, duration: 0.75 }

export const AnimationCarousel: { [key: string]: Variants } = {
  Item: {
    active: custom => {
      const { itemLength } = custom
      return {
        flex: itemLength > 1 ? "0 0 80%" : 1,
        zIndex: 2,
        transition: localTransitionItem,
      }
    },
    inActive: {
      flex: "0 0 20%",
      zIndex: 1,
      transition: { ...localTransitionItem, delay: 0.5 },
    },
  },
  Image: {
    active: {
      x: "-50%",
      y: "-50%",
      transition: localTransitionImage,
    },
    inActive: {
      x: "-50%",
      y: "-50%",
      transition: localTransitionImage,
    },
  },
  ItemHeadlineI: {
    active: {
      display: "block",
      x: 0,
      opacity: 1,
      zIndex: 2,
      transition: { localTransitionItem, delay: 0.24, ease: "easeInOut" },
    },
    inActive: {
      display: "none",
      x: "4%",
      opacity: 0,
      zIndex: 1,
      transition: { localTransitionItem, delay: 0, ease: "easeInOut" },
    },
  },
}
