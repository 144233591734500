import React, { useRef } from "react"
import { useScroll, useTransform, motion as m } from "framer-motion"

import { color } from "common/settings"
import { I_Headline } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Image } from "components/Image"
import { Text } from "components/Text"

import { Paragraph as P } from "components/Paragraph/styles"
import { World as W } from "./styles"

interface Props {
  body: {
    headline: I_Headline
    paragraphI: string
    paragraphII: string
  }
}

export const World: React.FC<Props> = props => {
  const {
    body: { headline, paragraphI, paragraphII },
  } = props
  const refWorld = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: refWorld,
    offset: ["start center", "end end"],
  })

  const backgroundColor = useTransform(
    scrollYProgress,
    [0, 0.16],
    [color.inverted, color.darkBlue]
  )

  const img_Opacity = useTransform(scrollYProgress, [0, 0.16], [0, 1])
  const img_Y = useTransform(scrollYProgress, [0, 1.5], [50, -50])

  const hI_Y = useTransform(scrollYProgress, [0, 0.16], [50, 0])
  const hI_Opacity = useTransform(scrollYProgress, [0, 0.16], [0, 1])

  const subline_Y = useTransform(scrollYProgress, [0, 0.2], [50, 0])
  const subline_Opacity = useTransform(scrollYProgress, [0, 0.2], [0, 1])

  const paragraph_Y = useTransform(scrollYProgress, [0, 0.28], [75, 0])
  const paragraph_Opacity = useTransform(scrollYProgress, [0, 0.28], [0, 0.64])

  const renderHeader = () => (
    <m.div style={{ y: hI_Y, opacity: hI_Opacity }}>
      <Text
        modifiers={["subline", "spacingTop", "color"]}
        color={"inverted"}
        spacingTop={"xxxl"}
      >
        {Text({ type: "span", children: headline.I })}
        <br />
        {headline.II &&
          Text({
            type: "span",
            modifiers: ["fontWeight"],
            weight: 200,
            children: headline.II,
          })}
      </Text>
    </m.div>
  )

  const renderSubline = () => (
    <m.div style={{ y: subline_Y, opacity: subline_Opacity }}>
      {Text({
        modifiers: [
          "title",
          "color",
          "fontWeight",
          "spacingTop",
          "spacingBottom",
        ],
        color: "inverted",
        weight: 300,
        spacingTop: "xl",
        spacingBottom: "xl",
        children: paragraphI,
      })}
    </m.div>
  )

  const renderParagraph = () => (
    <m.div style={{ y: paragraph_Y, opacity: paragraph_Opacity }}>
      <P.Block
        modifiers={["inverted"]}
        dangerouslySetInnerHTML={{
          __html: `<p>${paragraphII}</p>`,
        }}
      />
    </m.div>
  )

  return (
    <W.Block ref={refWorld} style={{ backgroundColor }}>
      <W.Inner>
        <Grid modifiers={["maxWidth", "spacingBottom"]} spacingBottom={"xxxl"}>
          <GridItem>
            {renderHeader()}
            {renderSubline()}
            {renderParagraph()}
          </GridItem>
        </Grid>
      </W.Inner>
      <W.Image style={{ opacity: img_Opacity }}>
        <m.div style={{ y: img_Y }}>
          <Image src={"world.png"} title={"world"} />
        </m.div>
      </W.Image>
    </W.Block>
  )
}
