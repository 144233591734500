import React, { useState } from "react"
import { AnimatePresence } from "framer-motion"

import { I_Options } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Svg } from "components/Svg"
import { Image } from "components/Image"

import { Video as V } from "./styles"
import { VideoImage as animImage } from "./animation"

interface Props {
  options: I_Options
}

export const Video: React.FC<Props> = props => {
  const [showVideo, setShowVideo] = useState<boolean>(false)
  const {
    options: { paddingTop, paddingBottom },
  } = props

  return (
    <Grid
      modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
      spacingTop={paddingTop}
      spacingBottom={paddingBottom}
    >
      <GridItem>
        <V.Block>
          <AnimatePresence exitBeforeEnter>
            {!showVideo ? (
              <V.Image onClick={() => setShowVideo(true)}>
                <V.Play
                  style={{ x: "-50%", y: "-50%" }}
                  whileHover={{ scale: 1.05, x: "-50%", y: "-50%" }}
                >
                  <Svg type={"play"} />
                </V.Play>
                <Image alt={"landscape"} src={"landscape-bih.jpg"} />
              </V.Image>
            ) : (
              <V.Frame
                src="https://www.youtube.com/embed/YERSErOdifk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </AnimatePresence>
        </V.Block>
      </GridItem>
    </Grid>
  )
}
