import styled from "styled-components"
import { breaks } from "common/styles"

export const IntroColumn = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;

  ${breaks.lg.up} {
    justify-content: flex-end;
  }
`
