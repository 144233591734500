import React from "react"
import { AnimatePresence } from "framer-motion"
import { RiCloseFill } from "react-icons/ri"

import { color } from "common/settings"
import { Grid, GridItem } from "components/Grid"
import { Image, Props as I_Image } from "components/Image"
import { Text } from "components/Text"

import { Modal as M } from "./styles"
import { AnimationModal as AM } from "./animation"
import { Paragraph as P } from "components/Paragraph/styles"

interface Props {
  image?: I_Image
  imageInline?: I_Image
  title?: string
  open: boolean
  delegateOpen: (open: boolean) => void
  paragraph?: string
}

export const Modal: React.FC<Props> = props => {
  const { paragraph, title, image, imageInline, open, delegateOpen } = props

  const renderTitle = (title: string) => (
    <Text
      modifiers={[
        "subline",
        "fontWeight",
        "fontAlignCenter",
        "color",
        "spacingTop",
        "spacingBottom",
      ]}
      weight={200}
      color="inverted"
      spacingTop={"xxl"}
      spacingBottom={"xl"}
    >
      {title}
    </Text>
  )

  const renderContent = () => (
    <Grid
      modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
      spacingTop={"xl"}
      spacingBottom={"xl"}
    >
      {imageInline && (
        <GridItem
          modifiers={["flex", "alignCenter"]}
          responsive={{ lg: { span: 6 } }}
        >
          <M.ImageInline>
            <Image src={imageInline.src} alt={imageInline.alt} />
          </M.ImageInline>
        </GridItem>
      )}
      {title && (
        <GridItem
          modifiers={["flex", "alignCenter"]}
          responsive={{ lg: { span: 6 }, xxl: { span: 7 } }}
        >
          <div style={{ flex: 1, maxWidth: "800px" }}>{renderTitle(title)}</div>
        </GridItem>
      )}
      <GridItem
        modifiers={["flex", "alignCenter"]}
        responsive={{ lg: { start: 7, span: 6 }, xxl: { start: 8, span: 4 } }}
      >
        <div>
          {paragraph && (
            <P.Block dangerouslySetInnerHTML={{ __html: paragraph }} />
          )}
        </div>
      </GridItem>
    </Grid>
  )

  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <M.Block
          initial={"close"}
          animate={"open"}
          exit={"exit"}
          variants={AM.Block}
        >
          <M.Container>
            <M.Content>
              <M.Close onClick={() => delegateOpen(!open)}>
                <RiCloseFill size={24} color={color.inverted} />
              </M.Close>
              {renderContent()}
            </M.Content>
            {image && <Image src={image.src} alt={image.alt} />}
          </M.Container>
        </M.Block>
      )}
    </AnimatePresence>
  )
}
