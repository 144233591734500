import styled from "styled-components"
import { motion as m } from "framer-motion"

import { pxToRem } from "common/functions"
import { breaks } from "common/styles"

export const VideoPlay = styled(m.div)`
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: ${pxToRem(124)};
  height: ${pxToRem(124)};
  cursor: pointer;

  transform: translate(-50%, -50%);

  ${breaks.sm.up} {
    width: ${pxToRem(224)};
    height: ${pxToRem(224)};
  }

  ${breaks.lg.up} {
    width: ${pxToRem(320)};
    height: ${pxToRem(320)};
  }
`
