import styled from "styled-components"
import { pxToRem } from "common/functions"
import { gaps } from "common/styles"

export const GraphicLegend = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${gaps.xxl};

  & .row {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    margin-bottom: ${gaps.l};
  }

  & div.index {
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
    margin-right: ${gaps.l};
    background: yellow;
  }
`
