import styled from "styled-components"

export const BlockquoteGallery = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`
