import styled from "styled-components"
import { motion as m } from "framer-motion"
import { pxToRem } from "common/functions"
import { breaks } from "common/styles"

import { Paragraph as P } from "components/Paragraph/styles"

export const WorldInner = styled(m.div)`
  height: 100vh;

  & ${P.Block} {
    max-width: ${pxToRem(720)};

    ${breaks.xxxl.up} {
      max-width: ${pxToRem(960)};
    }
  }
`
