import { Carousel as Block } from "./Carousel.styles"
import { CarouselItemBanner as Banner } from "./CarouselItemBanner.styles"
import { CarouselItem as Element } from "./CarouselItem.styles"
import { CarouselItemImage as Image } from "./CarouselItemImage.styles"
import { CarouseItemInner as Inner } from "./CarouseItemInner.styles"
import { CarouselItemHeader as Header } from "./CarouselItemHeader.styles"

export const Carousel = {
  Block,
  Item: {
    Element,
    Banner,
    Image,
    Inner,
    Header,
  },
}
