import styled from "styled-components"
import { pxToRem } from "common/functions"
import { color } from "common/settings"

interface I_GraphicLabelBar {
  color: string
}

export const GraphicLabel = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0%;
  left: 50%;
  width: ${pxToRem(40)};
  height: 40px;
  transform: translate(-50%, 0%);
  background-color: white;
  border: ${pxToRem(2)} solid ${color.inverted};
`

export const GraphicLabelBar = styled.div<I_GraphicLabelBar>`
  height: 100%;
  width: 20%;
  background-color: ${({ color }) => color};

  &:first-of-type {
    width: 35%;
  }
`
