import styled from "styled-components"
import { color } from "common/settings"
import { breaks } from "common/styles"

export const DiagramCircleBig = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 40vw;
  height: 40vw;
  border: 1px solid ${color.inverted};
  transform: translate(-50%, -50%);
  border-radius: 50%;

  ${breaks.sm.up} {
    display: none;
    width: 70vw;
    height: 70vw;
  }

  ${breaks.md.up} {
    display: block;
    width: 65vw;
    height: 65vw;
  }

  ${breaks.lg.up} {
    width: 50vw;
    height: 50vw;
  }

  ${breaks.xl.up} {
    width: 43vw;
    height: 43vw;
  }
`
