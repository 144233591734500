import React, { useState } from "react"

import { I_Headline } from "common/types"
import { Props as I_Image } from "components/Image"
import { CarouselItem } from "./CarouselItem"

import { Carousel as C } from "./styles"

export interface I_CarouselItem {
  headline: I_Headline
  title?: string
  meta?: string
  image: I_Image
  imageInline: I_Image
  banner?: I_Image
  listItems: string[]
  paragraph?: string
}

interface Props {
  lang: string
  body: {
    carouselItems: I_CarouselItem[]
  }
}

export const Carousel: React.FC<Props> = props => {
  const {
    lang,
    body: { carouselItems },
  } = props

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const renderItem = (item: I_CarouselItem, index: number) => (
    <CarouselItem
      itemLength={carouselItems.length}
      activeIndex={activeIndex}
      index={index}
      headline={item.headline}
      image={item.image}
      title={item.title}
      imageInline={item.imageInline}
      banner={item.banner}
      listItems={item.listItems}
      paragraph={item.paragraph}
      meta={item.meta}
      lang={lang}
      delegateClick={newActiveIndex => setActiveIndex(newActiveIndex)}
    />
  )

  return (
    <C.Block>
      {carouselItems.map((item, index) => renderItem(item, index))}
    </C.Block>
  )
}
