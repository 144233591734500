import { Grid, GridItem } from "components/Grid"
import { Text } from "components/Text"
import React, { useRef } from "react"

import { Graphic as G } from "./styles"
import { I_Headline } from "common/types"

interface I_Item {
  title: string
}

interface Props {
  body: {
    headline: I_Headline
    paragraphI: string
    items: I_Item[]
  }
}

export const GraphicScaffold: React.FC<Props> = props => {
  const {
    body: { headline, items, paragraphI },
  } = props

  const refDragContraints = useRef<HTMLDivElement>(null)

  return (
    <div
      style={{ width: "100vw", overflowY: "visible", overflowX: "hidden" }}
      ref={refDragContraints}
    >
      <G.Block
        drag={"x"}
        dragConstraints={refDragContraints}
        modifiers={["minWidth"]}
      >
        <Grid modifiers={["maxWidth"]}>
          <GridItem>
            <div>
              <G.Tile modifiers={["single"]}>
                {Text({
                  modifiers: ["body", "fontAlignCenter"],
                  children: headline.I,
                })}
              </G.Tile>
            </div>
            <div>
              <G.Tile modifiers={["single"]}>
                {Text({
                  modifiers: ["body", "fontAlignCenter"],
                  children: headline.II,
                })}
              </G.Tile>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {items.map(item => (
                <G.Tile modifiers={["single", "squared"]}>
                  {Text({
                    modifiers: ["body", "fontAlignCenter"],
                    children: item.title,
                  })}
                </G.Tile>
              ))}
            </div>
            <div>
              <G.Tile modifiers={["single", "emphasized"]}>
                {Text({
                  modifiers: ["body", "fontAlignCenter"],
                  children: paragraphI,
                })}
              </G.Tile>
            </div>
          </GridItem>
        </Grid>
      </G.Block>
    </div>
  )
}
