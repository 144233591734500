import styled from "styled-components"
import { pxToRem } from "common/functions"
import { breaks } from "common/styles"

export const GraphicText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: ${pxToRem(26)};
  text-align: center;
  transform: translate(-50%, -50%);

  ${breaks.sm.up} {
    font-size: ${pxToRem(32)};
  }

  ${breaks.md.up} {
    font-size: ${pxToRem(36)};
  }

  ${breaks.lg.up} {
    font-size: 200%;
  }
`
