import styled, { css } from "styled-components"
import { motion as m } from "framer-motion"
import { breaks, gaps } from "common/styles"
import { applyModifiers, pxToRem } from "common/functions"

export type T_PitchText = keyof typeof Modifiers
export interface I_PitchText {
  modifiers?: T_PitchText[]
}

const Modifiers = {
  posRelative: () => css`
    position: relative;
  `,
  subline: () => css`
    top: 32%;

    ${breaks.md.up} {
      top: 40%;
    }

    ${breaks.lg.up} {
      top: 32%;
    }
  `,
}

export const PitchText = styled(m.div)<I_PitchText>`
  margin-top: 0;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: 450px) {
    margin-top: ${gaps.xxxxl};
  }

  & + & {
    position: absolute;
    top: ${gaps.l};
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
