import styled from "styled-components"
import { pxToRem } from "common/functions"
import { breaks, gaps } from "common/styles"

export const ModalImageInline = styled.div`
  position: relative;
  width: 100%;
  min-height: ${pxToRem(560)};

  ${breaks.lg.up} {
    min-height: ${pxToRem(720)};
  }

  & .gatsby-image-wrapper {
    width: 100%;

    & img {
      object-fit: contain !important;
    }
  }
`
