import styled from "styled-components"
import { motion as m } from "framer-motion"
import { rgba } from "polished"
import { color } from "common/settings"

export const Modal = styled(m.div)`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vw;
  background-color: ${rgba(color.dark, 0.2)};
`
