import styled, { css } from "styled-components"
import { rgba } from "polished"
import { motion as m } from "framer-motion"
import { applyModifiers, pxToRem } from "common/functions"

import { breaks } from "common/styles"
import { color } from "common/settings"

import { CarouseItemInner } from "./CarouseItemInner.styles"

type T_CarouselItem = keyof typeof Modifiers
interface I_CarouselItem {
  modifiers?: T_CarouselItem[]
}

const Modifiers = {
  fullHeight: () => css`
    height: auto;

    ${breaks.lg.up} {
      height: auto;
    }

    & ${CarouseItemInner} {
      position: relative;
    }
  `,
  darken: () => css`
    &:after {
      background: ${rgba(color.contrast, 0.64)};
    }
  `,
  noImage: () => css`
    background-color: ${color.dark};
    background-image: url(/pattern-II.png);
  `,
}

export const CarouselItem = styled(m.div)<I_CarouselItem>`
  flex: 1;
  position: relative;
  height: 72vh;
  min-height: ${pxToRem(320)};

  ${breaks.lg.up} {
    height: 90vh;
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
