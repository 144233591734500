import React, { useState } from "react"

import { Headline } from "components/Headline"
import { Link } from "components/Link"
import { I_Headline } from "common/types"

import { Props as I_Image } from "components/Image"
import { Modal } from "components/Modal"

import { Tile as T } from "./styles"
import { ScrollWrapper } from "components/ScrollWrapper"
import { Text } from "components/Text"

interface Props {
  headline: I_Headline
  lang: string
  title?: string
  listItems?: string[]
  paragraph?: string
  imageInline?: I_Image
}

export const Tile: React.FC<Props> = props => {
  const { headline, title, paragraph, imageInline, listItems, lang } = props
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <div>
        <T.Block>
          {ScrollWrapper({
            children: Headline({
              headline: headline,
              headlineSize: "subline",
            }),
          })}
          {listItems && (
            <>
              {Text({
                modifiers: ["spacingTop"],
                spacingTop: "xl",
                children: <></>,
              })}
              {listItems.map((item, index) => (
                <div key={index}>
                  {Text({
                    modifiers: ["spacingTop"],
                    spacingTop: "l",
                    children: ScrollWrapper({
                      children: Text({
                        modifiers: ["tertiary", "fontWeight"],
                        weight: 200,
                        children: item,
                      }),
                    }),
                  })}
                </div>
              ))}
            </>
          )}

          {paragraph && (
            <ScrollWrapper>
              <br />
              <br />
              {Link({
                title: lang === "en" ? "Learn More" : "Mehr erfahren",
                modifiers: ["paragraph"],
                delegateClick: () => setOpen(!open),
              })}
            </ScrollWrapper>
          )}
        </T.Block>
      </div>
      {Modal({
        paragraph,
        title,
        imageInline,
        open,
        delegateOpen: updateOpen => setOpen(updateOpen),
      })}
    </>
  )
}
