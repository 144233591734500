import React, { useRef } from "react"
import { useScroll, useTransform, motion as m } from "framer-motion"

import { I_Headline } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Image, Props as I_Image } from "components/Image"
import { Text } from "components/Text"
import { Hero as H } from "./styles"

interface Props {
  body: {
    meta?: string
    headline: I_Headline
    paragraphI: string
    paragraphII: string
    image: I_Image
  }
}

export const Hero: React.FC<Props> = props => {
  const {
    body: { meta, headline, paragraphI, paragraphII, image },
  } = props

  const refHero = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: refHero,
    offset: ["start start", "center start"],
  })
  const imgScale = useTransform(scrollYProgress, [0, 0.16], [1, 1.08])
  const hIOpacity = useTransform(scrollYProgress, [0, 0.16], [1, 0])
  const hII_Y = useTransform(scrollYProgress, [0.04, 0.16], [50, 0])
  const pI_Y = useTransform(scrollYProgress, [0.08, 0.24], [50, 0])
  const pI_Opacity = useTransform(scrollYProgress, [0.08, 0.24], [0, 1])
  const pII_Y = useTransform(scrollYProgress, [0.12, 0.25], [50, 0])
  const pII_Opacity = useTransform(scrollYProgress, [0.12, 0.26], [0, 1])

  const renderHeadline = (headline: string) => (
    <H.Headline style={{ opacity: hIOpacity, y: hII_Y }}>
      {meta && (
        <m.div
          style={{
            position: "relative",
            top: -50,
            opacity: hIOpacity,
            y: hII_Y,
          }}
        >
          {Text({
            modifiers: ["paragraph", "color", "spacingBottom"],
            spacingBottom: "xl",
            color: "inverted",
            children: meta,
          })}
        </m.div>
      )}
      {Text({
        modifiers: ["subline", "color", "fontWeight"],
        weight: 200,
        color: !image ? "dark" : "inverted",
        children: headline,
      })}
    </H.Headline>
  )

  return (
    <H.Block ref={refHero}>
      <H.Inner>
        <H.Content>
          <Grid modifiers={["maxWidth"]}>
            <GridItem>{headline.II && renderHeadline(headline.II)}</GridItem>
            {paragraphI && (
              <GridItem responsive={{ lg: { span: 6 }, xl: { span: 7 } }}>
                <m.div style={{ opacity: pI_Opacity, y: pI_Y }}>
                  {Text({
                    modifiers: ["subline", "fontWeight", "color"],
                    weight: 200,
                    color: !image ? "dark" : "inverted",
                    children: paragraphI,
                  })}
                </m.div>
              </GridItem>
            )}
            {paragraphII && (
              <GridItem responsive={{ lg: { span: 6 }, xl: { span: 5 } }}>
                <m.div style={{ opacity: pII_Opacity, y: pII_Y }}>
                  {Text({
                    modifiers: [
                      "title",
                      "fontWeight",
                      "lineHeightText",
                      "color",
                    ],
                    weight: 300,
                    color: !image ? "dark" : "inverted",
                    children: paragraphII,
                  })}
                </m.div>
              </GridItem>
            )}
          </Grid>
        </H.Content>
      </H.Inner>
      {image && (
        <H.Image>
          <m.div style={{ scale: imgScale, height: "100%" }}>
            <Image src={image.src} alt={image.alt} />
          </m.div>
        </H.Image>
      )}
    </H.Block>
  )
}
