import styled from "styled-components"
import { motion as m } from "framer-motion"
import { rgba } from "polished"
import { color } from "common/settings"

export const HeroImage = styled(m.div)`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  will-change: transform;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${rgba(color.contrast, 0.16)};
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      ${rgba(color.contrast, 0)},
      ${rgba(color.contrast, 0.24)}
    );
  }

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    & img {
      object-align: center top;
    }
  }
`
