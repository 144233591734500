import React, { useState } from "react"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import { AnimatePresence, motion as m } from "framer-motion"

import { transition, color } from "common/settings"
import { Image, Props as I_Image } from "components/Image"
import { Carousel as C } from "components/Carousel/styles"
import { I_Headline, I_Options } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Headline } from "components/Headline"
import { Svg } from "components/Svg"
import { Text } from "components/Text"

import { BlockquoteGallery as B } from "./styles"
import { AnimationBlockqoteGallery as ACB } from "./animation"

const localTransition = transition

export interface I_Blockquote {
  headline: I_Headline
  paragraphI: string
  paragraphII: string
  image: I_Image
}

interface Props {
  options: I_Options
  body: {
    image: I_Image
    blockquotes: I_Blockquote[]
  }
}

export const BlockquoteGallery: React.FC<Props> = props => {
  const {
    options: { inlineLogo },
    body: { image, blockquotes },
  } = props

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const handleClick = (step: number, direction: "next" | "prev") => {
    if (direction === "next" && activeIndex + step > blockquotes.length - 1) {
      setActiveIndex(0)
    } else if (direction === "prev" && activeIndex + step < 0) {
      setActiveIndex(blockquotes.length - 1)
    } else {
      setActiveIndex(activeIndex + step)
    }
  }

  const renderAnimationWrapper = (el: JSX.Element, delay: number) => (
    <m.div
      initial={"hidden"}
      animate={"visible"}
      exit={"hidden"}
      variants={ACB.Element}
      transition={{ ...localTransition, delay }}
    >
      {el}
    </m.div>
  )

  const renderBlockquote = (blockquote: I_Blockquote) => (
    <AnimatePresence exitBeforeEnter>
      <Grid>
        <GridItem modifiers={["flex", "alignCenter", "justifyCenter"]}>
          <B.Blockquote.Element>
            {renderAnimationWrapper(
              <Image src={blockquote.image.src} alt={blockquote.image.alt} />,
              0
            )}
            {blockquote.headline &&
              renderAnimationWrapper(
                <>
                  {Text({
                    modifiers: ["spacingTop", "spacingBottom"],
                    spacingTop: "xl",
                    spacingBottom: blockquote.paragraphI ? "l" : "xl",
                    children: Headline({
                      headline: blockquote.headline,
                      headlineSize: "tertiary",
                    }),
                  })}
                </>,
                0.08
              )}
            {blockquote.paragraphI &&
              renderAnimationWrapper(
                <>
                  {Text({
                    type: "p",
                    modifiers: [
                      "paragraph",
                      "fontWeight",
                      "color",
                      "lineHeightText",
                    ],
                    weight: 200,
                    color: "inverted",
                    children: blockquote.paragraphI,
                  })}
                </>,
                0.16
              )}

            {renderAnimationWrapper(
              <>
                {Text({
                  type: "p",
                  modifiers: ["small", "color", "spacingTop"],
                  color: "inverted",
                  spacingTop: blockquote.paragraphI ? "xl" : "none",
                  children: blockquote.paragraphII,
                })}
              </>,
              0.24
            )}
          </B.Blockquote.Element>
        </GridItem>
      </Grid>
    </AnimatePresence>
  )

  return (
    <C.Block>
      <C.Item.Element modifiers={["fullHeight", "darken", "noImage"]}>
        <C.Item.Inner active={true}>
          <B.Block>
            {blockquotes.map(
              (item, index) => activeIndex === index && renderBlockquote(item)
            )}
            <B.ButtonGroup
              initial={"hidden"}
              animate={"visible"}
              exit={"hidden"}
              variants={ACB.Element}
              transition={{ ...localTransition, delay: 0.32 }}
            >
              <B.Button onClick={() => handleClick(-1, "prev")}>
                {RiArrowLeftLine({
                  fontSize: "inherit",
                  color: color.inverted,
                })}
              </B.Button>
              <B.Button onClick={() => handleClick(1, "next")}>
                {RiArrowRightLine({
                  fontSize: "inherit",
                  color: color.inverted,
                })}
              </B.Button>
            </B.ButtonGroup>
          </B.Block>
        </C.Item.Inner>
        {image && (
          <C.Item.Image>
            <Image src={image.src} alt={image.alt} />
          </C.Item.Image>
        )}
        {inlineLogo && (
          <B.Image>
            <Svg type={"getLogoInline"} />
          </B.Image>
        )}
      </C.Item.Element>
    </C.Block>
  )
}
