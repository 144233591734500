import React from "react"

import { Text } from "components/Text"
import { Paragraph as P } from "components/Paragraph/styles"
import { Accordion as A } from "./styles"

import { I_AccordionItem } from "./index"

interface Props extends I_AccordionItem {
  index: number
  activeIndex: number
  delegateClick: (index: number) => void
}

export const AccordionItem: React.FC<Props> = props => {
  const { index, activeIndex, title, paragraph, delegateClick } = props

  const renderTitle = (title: string) => (
    <Text
      type={"h3"}
      modifiers={["tertiary", "fontWeight", "spacingTop"]}
      weight={300}
      spacingTop={"m"}
    >
      {title}
    </Text>
  )
  const renderParagraph = (paragraph: string) => (
    <P.Block dangerouslySetInnerHTML={{ __html: paragraph }} />
  )

  return (
    <A.Block key={index + 1}>
      <A.Header onClick={() => delegateClick(index + 1)}>
        {renderTitle(title)}
      </A.Header>
      <A.Content modifiers={activeIndex === index + 1 ? ["open"] : undefined}>
        {renderParagraph(paragraph)}
      </A.Content>
    </A.Block>
  )
}
