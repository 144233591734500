import styled from "styled-components"
import { motion as m } from "framer-motion"

import { pxToRem } from "common/functions"
import { breaks, gaps } from "common/styles"

export const HeroHeadline = styled(m.div)`
  position: absolute;
  bottom: ${gaps.xxxxl};
  max-width: ${pxToRem(720)};

  ${breaks.lg.up} {
    max-width: ${pxToRem(800)};
  }

  ${breaks.xxxl.up} {
    max-width: ${pxToRem(1200)};
  }
`
