import React from "react"
import { Grid, GridItem } from "components/Grid"
import { I_Headline } from "common/types"

import { KeyFactsItem, Props as I_KeyFactsItem } from "./KeyFactsItem"
import { ScrollWrapper } from "components/ScrollWrapper"
import { Text } from "components/Text"

import { KeyFacts as K } from "./styles"

interface Props {
  body: {
    headline: I_Headline
    paragraphI?: string
    source?: string
    bullets: I_KeyFactsItem[]
  }
}

export const KeyFacts: React.FC<Props> = props => {
  const {
    body: { headline, paragraphI, bullets, source },
  } = props

  const renderHeadline = (headline: I_Headline) => (
    <>
      {ScrollWrapper({
        children: (
          <Text
            modifiers={["headline", "fontWeight", "fontAlignCenter"]}
            weight={300}
          >
            <Text modifiers={["color"]} color={"middle"}>
              {headline.I}
            </Text>
            <Text modifiers={["color"]} color={"highlight"}>
              {headline.II}
            </Text>
          </Text>
        ),
      })}
      {paragraphI &&
        ScrollWrapper({
          children: Text({
            modifiers: ["title", "fontAlignCenter", "spacingTop"],
            spacingTop: "xxl",
            children: paragraphI,
          }),
        })}
      <Text modifiers={["spacingTop"]} spacingTop={"xxxl"}>
        <></>
      </Text>
    </>
  )

  return (
    <K.Block>
      <Grid
        modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
        spacingTop={"xxxl"}
        spacingBottom={"xxxl"}
      >
        <GridItem>{renderHeadline(headline)}</GridItem>
        {bullets.map((item, index) => (
          <GridItem
            key={index}
            responsive={{ sm: { span: 6 }, lg: { span: 6 } }}
          >
            {KeyFactsItem({ ...item })}
          </GridItem>
        ))}
        {source && (
          <GridItem>
            <ScrollWrapper>
              {Text({
                modifiers: ["color", "fontWeight"],
                weight: 300,
                color: "light",
                children: source,
              })}
            </ScrollWrapper>
          </GridItem>
        )}
      </Grid>
    </K.Block>
  )
}
